$import-google-fonts: true !default;
$font-url: 'https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin,latin-ext' !default;
$font-name: 'Lato' !default;
$font-family: $font-name, 'Helvetica Neue', Arial, Helvetica, sans-serif !default;
$use-custom-scrollbars: true !default;
$icons-font-path: '../icons' !default;
$flags-image-path: '../images' !default;

$font-color: rgba(0, 0, 0, 0.87) !default;

$red: #dd324f;
$orange: #dc634f;
$yellow: #f6c358;
$olive: #9dcb49;
$green: #21ba45;
$teal: #3bb2dc;
$blue: #13418b;
$violet: #6435c9;
$purple: #972878;
$pink: #e03997;
$brown: #a5673f;
$grey: #767676;
$black: #1b1c1d;
